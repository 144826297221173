import React, { useState, Fragment, useRef } from "react"
import { Formik } from "formik"
import Axios from "axios"
import Button from "./button"
import { Alert, Form } from "react-bootstrap"
import { Dialog, Transition } from "@headlessui/react"

const Action = ({ action, isOpen, openModal, closeModal }) => {
  const [response, setResponse] = useState("")
  const [error, setError] = useState("")

  const { fields } = action.form

  const reducer = (accumulator, currentItem) => {
    const newValue = {
      [currentItem.name]: "",
    }
    return { ...accumulator, ...newValue }
  }

  let initialValues = fields.reduce(reducer, [])
  let focusRef = useRef(null)
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 overflow-y-auto"
          onClose={closeModal}
          initialFocus={focusRef}
        >
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Formik
                  initialValues={initialValues}
                  onSubmit={(values, { setSubmitting }) => {
                    let data = Object.keys(initialValues).reduce(
                      (obj, item) => {
                        return {
                          ...obj,
                          [item]: values[item],
                        }
                      },
                      {}
                    )
                    Axios.post(action.form.url, data)
                      .then(function (response) {
                        setError("")
                        setSubmitting(false)
                        setResponse(action.form.messages.success)
                        window.dataLayer = window.dataLayer || []
                        window.dataLayer.push({
                          event: "formSubmissionSuccess",
                          formId: action.form.name,
                        })
                        /*window.fbq = window.fbq || []
                        window.fbq("track", "Lead")
                        setTimeout(() => {
                          handleClose()
                          setResponse("")
                        }, 2000)
                        */
                      })
                      .catch(function (error) {
                        setSubmitting(false)
                        if (!error.data) {
                          setError("Network Error")
                        } else {
                          setError(error.data)
                        }
                      })
                  }}
                >
                  {({ values, handleChange, handleSubmit, isSubmitting }) => {
                    const loadingWheel = isSubmitting ? (
                      <div className="flex justify-center space-x-2">
                        <span>Sending</span>
                       <svg
                          class="animate-spin mt-1 h-5 w-5 text-white object-center"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            class="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            stroke-width="4"
                          ></circle>
                          <path
                            class="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      </div>
                    ): "Send"
                    return (
                      <Form onSubmit={handleSubmit}>
                        {error ? (
                          <Alert variant="danger">
                            <h4>Oh snap! Something went wrong!</h4>
                            <br />
                            {error}
                            <br />
                            <br />
                            <p>Please complete the form and try again.</p>
                          </Alert>
                        ) : (
                          ""
                        )}

                        {response ? (
                          <Alert variant="success">
                            <span>{response}</span>
                          </Alert>
                        ) : (
                          <>
                            <div className="flex flex-row justify-between">
                              <Dialog.Title as="h3" className="text-2xl">
                                {action.form.title}
                              </Dialog.Title>
                              <button className="bg-transparent">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="h-5 w-5 text-gray-400"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  onClick={closeModal}
                                  alt="close"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                              </button>
                            </div>

                            <Dialog.Description className="text-gray-800 py-4">
                              {action.form.description}
                            </Dialog.Description>

                            {fields.map((field, index) => {
                              //Enables making autofocus on the first field of the form

                              const focus = index === 0 ? focusRef : null
                              const isRequired =
                                field.required === "true" ? true : false
                              switch (field.type) {
                                case "textarea":
                                  return (
                                    <Form.Group
                                      key={`input_${index}`}
                                      controlId={field.name}
                                    >
                                      <Form.Control
                                        as="textarea"
                                        required={field.required}
                                        type={field.type}
                                        placeholder={field.label}
                                        name={field.name}
                                        onChange={handleChange}
                                        value={values[field.name]}
                                        className="input-field"
                                      />
                                    </Form.Group>
                                  )
                                case "select":
                                  return (
                                    <Form.Group
                                      key={`input_${index}`}
                                      controlId={field.name}
                                    >
                                      <Form.Control
                                        as="select"
                                        required={field.required}
                                        placeholder={field.label}
                                        name={field.name}
                                        onChange={handleChange}
                                        value={values[field.name]}
                                        className="input-field"
                                      >
                                        <option value="">{field.label}</option>
                                        {field.options.map((option, index) => (
                                          <option key={`option_${index}`}>
                                            {option}
                                          </option>
                                        ))}
                                      </Form.Control>
                                    </Form.Group>
                                  )
                                default:
                                  return (
                                    <Form.Group
                                      key={`input_${index}`}
                                      controlId={field.name}
                                    >
                                      <Form.Control
                                        required={isRequired}
                                        className="input-field"
                                        type={field.type}
                                        placeholder={field.label}
                                        name={field.name}
                                        onChange={handleChange}
                                        value={values[field.name]}
                                        ref={focus}
                                      />
                                    </Form.Group>
                                  )
                              }
                            })}
                            <Button
                              onClick={openModal}
                              disabled={isSubmitting}
                              className="w-full"
                            >
                              {loadingWheel}
                            </Button>
                          </>
                        )}
                      </Form>
                    )
                  }}
                </Formik>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default Action
