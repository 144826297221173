import * as React from "react"
import Section from "../components/section"

const Sections = ({ openModal, closeModal, isOpen, query, theme }) => {

  const sections = query.content.sections.map(section => {
    const header = section.items.find(y => y.data.Content_type === "Header")

    let title = null
    let description = null

    if (header) {
      title = header.data.Asset_title
      description = header.data.Description_1
    }

    let type = section.items[0].data.Asset_type

    return {
      type,
      title,
      description,
      blocks: section.items
        .map(x => x.data)
        .filter(x => x.Content_type !== "Header"),
    }
  })
  return (
    <>
      {sections.map(section => {
        return (
          <Section
            key={`section_${section.type}`}
            id={section.type}
            section={section}
            openModal={openModal}
            closeModal={closeModal}
            isOpen={isOpen}
            theme={theme}
          />
        )
      })}
    </>
  ) 
}

export default Sections
