import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Sections from "../components/sections"
import Action from "../components/action"

const IndexPage = () => {
  //Handles contact form open/close
  let [isOpen, setIsOpen] = useState(false)
  function closeModal() {
    setIsOpen(false)
  }
  function openModal() {
    setIsOpen(true)
  }

  const query = useStaticQuery(
    graphql`
      query {
        company: airtable(table: { eq: "Basics" }) {
          data {
            Brand
            Style
            Palette
          }
        }
        content: allAirtable(
          filter: {
            table: { eq: "Business" }
            data: { Asset_type: { ne: "Brand" } }
          }
          sort: { fields: rowIndex }
        ) {
          sections: group(field: data___Asset_type, limit: 10) {
            items: nodes {
              data {
                Content_type
                Asset_type
                Asset_title
                Description_1
                Description_2
                Description_3
                Image {
                  localFiles {
                    childImageSharp {
                      gatsbyImageData(placeholder: NONE)
                    }
                  }
                }
                Symbol
                Additional_info
              }
            }
          }
        }
        site: airtable(table: { eq: "Sites" }) {
          data {
            Title
            Subtitle
            Form_data
            Content_alignment
          }
        }
      }
    `
  )


const businessName = query.company.data.Brand
  const formData = JSON.parse(query.site.data.Form_data)
  const theme = {
    style: query.company.data.Style,
    palette: query.company.data.Palette
  }
  const contentAlignment = query.site.data.Content_alignment
  const isCentered = contentAlignment === "Center" && "text-center"

  return (
    <Layout openModal={openModal} closeModal={closeModal} isOpen={isOpen} business={businessName} className={isCentered}>
      <Seo
        title={query.site.data.Title}
        description={query.site.data.Subtitle}
        business={businessName}
      />
      <Sections
        openModal={openModal}
        closeModal={closeModal}
        isOpen={isOpen}
        query={query}
        theme={theme}
        alignment={contentAlignment}
      />
      <Action
        action={formData.action}
        openModal={openModal}
        closeModal={closeModal}
        isOpen={isOpen}
      />
    </Layout>
  )
}

export default IndexPage
